@import "./vars.scss";

section.tldr {
  background: linear-gradient($tertiary -50%, $secondary-tint 60%);
  overflow: hidden;

  @include splitter-glide;

  h2 {
    @include media(sm) {
      font-size: 1em;
    }
  }

  h6 {
    @include media(sm) {
      opacity: .6;
    }
  }

  .content {
    @include adaptive-padding;

    max-width: 30rem;
    margin: 0 auto;
    border-radius: 1vw;
  }

  .tldr-more {
    font-size: .7rem;
    opacity: .7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 1rem;
    color: $text-inverse;

    i.gg-mouse {
      margin-top: .5rem;
      --ggs: 1.5;

      &:after {
        animation: lining-down 2s infinite;
      }
    }
  }

  .tldr-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: dense;
    margin-bottom: 1rem;

    gap: 2rem;

    @include media (gt-md-mid) {
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }

    @include media (lt-md-mid) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  .tldr-decoration {
    @include media(lt-md) {
      display: none;
    }

    &:nth-of-type(2n + 1) {
      text-align: right;
    }
  }

  .tldr-container {
    z-index: 1;
    position: relative;
    padding: 1rem;
    font-size: .7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;

    .morphing-shape {
      z-index: -1;
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      .shape {
        @include media (lt-md) {
          border-radius: 1rem !important;
        }

        @include media (sm) {
          border-radius: 0 !important;
        }
      }
    }

    span {
      z-index: 1;
    }

    @include media(lt-md) {
      margin: 1rem 0;
      padding: 1rem;
      font-size: .7rem;
    }

    @include media(sm) {
      padding: .5rem;
      font-size: .7rem;
    }
  }

  .tldr-summary {
    text-align: left;
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @include adaptive-padding;

    .tldr-header {
      font-size: 3em;
      margin: 0;
    }

    @include media (lt-md-mid) {
      grid-column: span 1;
    }

    @include media (sm) {
      font-size: .5rem;
    }
  }

  .morphing-shape {
    display: inline-block;
    color: white;
    --ggs: 1.5;

    @include media(sm) {
      --ggs: 2;
    }
  }
}